.no-box-shadow {
  box-shadow: 0 1px 1px rgba(16, 30, 115, 0.16);

  .material-table-custom {
    .MuiTable-root {
      box-shadow: none;
    }

    .custom-table-holder {
      &>.MuiPaper-root {
        box-shadow: none;
      }
    }
  }
}

.material-table-custom {
  width: 100%;

  .MuiTable-root {
    width: 100%;
    background-color: $white;
    box-shadow: 0 1px 1px rgba(16, 30, 115, 0.16);
    border-radius: 4px;

    &.table-transparent {
      background-color: transparent;
      box-shadow: none;
    }

    &.table-sm {

      .MuiTableCell-root {
        padding: 16px 20px;
      }

      .MuiTableBody-root {
        .MuiTableCell-root {
          padding: 7px 20px;
          height: 56px;
        }
      }
    }
  }

  .MuiTableHead-root {
    font-size: 14px;
    line-height: 22px;
  }

  .MuiTableRow-root {
    font-size: 14px;
    line-height: 22px;
  }

  .MuiTableCell-root {
    font-size: 14px;
    line-height: 22px;
    font-family: $base-font-family;
    border-bottom: 1px solid $gray-03;
    letter-spacing: 0.1px;
    padding: 20px 16px;
    background-color: transparent;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }

    .employee-data {
      min-width: 200px;
    }
  }

  .MuiTableCell-head {
    font-size: 12px;
    line-height: 16px;
    font-weight: $font-semi-bold;
    color: $d-blue-06;
  }

  .employee-data {
    p {
      margin-bottom: 0;

      &.caption {
        color: $d-blue-06;
      }
    }
  }

  .MuiSvgIcon-root {
    color: $d-blue-06;
    font-size: 12px;
  }

  .MuiTablePagination-toolbar {
    min-height: auto;

    .MuiTablePagination-spacer {
      display: none;
    }
  }

  .button-right {
    display: flex;
    justify-content: flex-end;
  }
}

.more-info {
  position: relative;

  &__button {
    cursor: pointer;
    color: $blue-07;
    display: inline-block;

    &:hover {

      &+.more-info__dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(44, 62, 94, 0.08), 0 1px 4px rgba(44, 62, 94, 0.24);
    padding: 16px;
    width: 213px;
    opacity: 0;
    visibility: hidden;
    transition: all, .2s;

    p {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__title {
    margin-bottom: 8px !important;
    font-weight: $font-medium;
  }
}

.employee-personal-data {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .MuiAvatar-root {
    margin-right: 8px;
  }

  .employee-data {

    &__name {
      font-size: 14px;
      line-height: 24px;
      font-weight: $font-medium;
      color: $black-03;
    }

    &__position {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $d-blue-06;
    }
  }
}

.custom-table-holder {

  .table-loader {
    background-color: $white;
    padding: 16px 8px;
    box-shadow: 0 1px 1px rgba(16, 30, 115, 0.16);
    border-radius: 4px;
    min-height: calc(100vh - 260px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-toolbar {
    background-color: $white;
    padding: 16px 18px;
    margin-bottom: 8px;
    box-shadow: 0 1px 1px rgba(16, 30, 115, 0.16);
    border-radius: 4px;
  }
}

.custom-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toolbar-heading {
    p {
      margin-bottom: 0;
      color: $black-03;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;

      [class*="icon-"] {
        color: $d-blue-06;
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  .toolbar-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__item {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    .MuiInputBase-input {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .MuiButton-root {
      &.button-select {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }
}

.material-table-pagination {
  padding: 10px 24px;

  .per-page-selection {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
    }

    .per-page-select {
      margin: 0 40px 0 16px;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  .MuiTablePagination-toolbar {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .MuiSelect-select {
    padding: 5px 5px;
    border: 1px solid #D0D4DB;
    border-radius: 4px;
  }

  .MuiSelect-icon {
    top: 12px;
    right: 7px;
  }
}

.comment-text {
  width: 260px;

  p, span {
    color: $black-03;
  }
}

.requested-column {
  width: 130px;

  span {
    color: $d-blue-06;
  }

  &__date {
    color: #08101E;
  }
}

.date-of-decision-text {
  color: #08101E;
}

.approves {
  min-width: 340px;
  padding-bottom: 10px;
  //overflow: hidden;
  //overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;

  .approval-status__item {
    margin-right: 8px;
    width: 40px;
    height: 40px;

    .MuiBadge-root {

      &:hover {
        .MuiAvatar-root {
          box-shadow: 0 0 0 4px $gray-04;
        }

        &+.approver-details {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .MuiAvatar-root {
    width: 40px;
    height: 40px;
  }
}

.absence-status {
  min-width: 210px;

  .status-with-approve {
    display: flex;
    align-items: center;

    .approve-status {
      margin-left: 10px;
    }
  }

  p, span {
    color: $d-blue-06;

    &.text-with-icon {
      &>span {
        color: $black-03;
      }
    }
  }
}

.employee-column {
  width: 250px;
}

.button-column {
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiButton-root {

    &:not(:last-child) {
      margin-right: 34px;
    }

    .MuiButton-label {
      white-space: nowrap;
    }
  }
}

.table-tab-header {
  background-color: $white;
  border-radius: 4px 4px 0 0;
  z-index: 999;
  border-bottom: 1px solid $gray-03;
}

.approver-details {
  position: absolute;
  margin-top: 6px;
  width: 300px;
  background-color: $white;
  box-shadow: 0 2px 2px rgba(44, 62, 94, 0.08), 0 1px 4px rgba(44, 62, 94, 0.24);
  border-radius: 4px;
  padding: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all, .2s;

  .employee-info-card {
    margin-top: 0;

    &.employee-column {
      width: 100%;
    }
  }

  p {
    font-size: 12px;
    line-height: 16px;
  }

  .text-medium {
    margin-bottom: 16px;
  }

  .comment-title {
    color: $d-blue-06;
    margin-bottom: 4px;
  }

  .comment-text {
    color: $black-03;
  }
}
