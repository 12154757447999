body {
  .MuiAvatar-root {
    width: 40px;
    height: 40px;
    border: none;
    font-family: $base-font-family;
    cursor: pointer;

    &.avatar-sm {
      width: 32px;
      height: 32px;
    }

    &.avatar-border {
      border: 1px solid $gray-05;

      &.border-success {
        border-color: $success;
      }

      &.border-error {
        border-color: $danger;
      }
    }

    &.avatar-md {
      width: 48px;
      height: 48px;
    }

    &.avatar-lg {
      width: 104px;
      height: 104px;
    }

    &.border-free {
      border: none;
    }
  }

  .MuiAvatarGroup-avatar {
    border-radius: 50%;

    &.MuiBadge-root {
      border: none;
    }
  }
}
