.approve-status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 4px;

  span {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    font-weight: $font-semi-bold;
    text-transform: uppercase;
  }

  &.approved {
    background: $green-01;

    span {
      color: $success;
    }
  }

  &.pending {
    background: $orange-01;

    span {
      color: $warning;
    }
  }

  &.declined {
    background: $red-01;

    span {
      color: $danger;
    }
  }
}
