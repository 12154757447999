body {
  .dropdown-menu {
    z-index: 999;
  }

  .MuiList-root,
  .profile-dropdown-info,
  .MuiMenuItem-root {
    outline: none;
  }

  .MuiList-root {
    &.dropdown-list {
      padding: 8px 0;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .dropdown-holder {
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0 2px 2px rgba(44, 62, 94, 0.08),
      0 1px 4px rgba(44, 62, 94, 0.24);
    z-index: 999;
    width: 260px;

    &.sm {
      width: 200px;
    }
  }

  .MuiMenuItem-root {
    font-family: $base-font-family;
    letter-spacing: 0.1px;
    color: $main-text-color;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 16px;

    &.checkbox-list-item {
      border: none;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.1px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: $gray-01;

      .menu-item {
        [class*='icon-'] {
          color: $icons-color;
        }
      }
    }

    .menu-item {
      width: 100%;
      display: flex;
      align-items: center;

      [class*='icon-'] {
        color: $icons-color;
        font-size: 14px;
        margin-left: 13px;
        @include animate(color);

        &.menu-item__icon-lg {
          font-size: 20px;
        }

        &:first-child {
          margin-left: 4px;
          margin-right: 13px;
        }

        &:last-child {
          margin-left: auto;
          margin-right: 0;
          padding-left: 13px;
        }
      }

      &.red {
        color: $red-02;

        [class*='icon-'] {
          color: $red-02;
        }
      }
    }
  }
}

// .MuiMenuItem-root {
//   &:not(:last-child) {
//     border-bottom: 1px solid $gray-03;
//   }
// }

.menu-divider {
  margin: 8px 0;
  height: 1px;
  border: none;
  background-color: $gray-03;
}

.profile-dropdown-menu {
  &.MuiButton-root {
    &:hover {
      background-color: transparent;

      .MuiButton-label {
        .user-name {
          color: $white;
        }
      }
    }
  }

  .MuiButton-label {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    font-weight: $font-regular;
    color: #f2f5f8;

    .user-name {
      display: block;
      margin: 0 7px;
      @include animate(color);
    }

    .icon-arrow-drop-down {
      font-size: 10px;
    }
  }
}

.profile-dropdown-info {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 16px;

  .profile-info {
    margin-left: 10px;

    p {
      margin-bottom: 0;
      color: #828282;
      letter-spacing: 0.2px;
      font-size: 12px;
      line-height: 16px;
      white-space: pre-wrap;

      &.profile-name {
        color: #1b1b1b;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
        font-weight: $font-medium;
        display: block;
        width: 195px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.employee-popper {
  z-index: 9;

  &__wrapper {
    margin-top: 0;
    padding: 16px;
  }

  .MuiBadge-root {
    margin-right: 16px;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &-name {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: $black-03;
    }

    &-position {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $d-blue-06;
    }
  }

  &__reason {
    font-size: 12px;
    line-height: 16px;
    color: $black-03;
    margin-bottom: 0;
  }

  &__title {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $black-03;
    margin-bottom: 4px;
  }

  &__date {
    font-size: 12px;
    line-height: 16px;
    font-weight: $font-medium;
    color: $black-03;
    margin-bottom: 16px;
  }
}

.vacation-info {
  &__wrapper {
    min-width: 202px;
    padding: 16px;

    p.vacation-info__text {
      font-size: 12px;
      display: block;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-weight: $font-medium;
    font-size: 12px;
    color: $black-03;
    display: block;
    margin-bottom: 0;
  }
}
