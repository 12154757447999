body {
  // start react select style
  .react-select-container {
    &.select-errors > .react-select__control {
      border: 1px solid $danger;
      min-height: 42px;
    }

    &.input-crop {
      //width: 260px;
    }

    *:not(.icon-arrow-drop-down) {
      font-size: 14px;
    }

    .react-select__control {
      min-height: 42px;
      border: 1px solid $gray-05;

      &.react-select__control--is-focused {
        min-height: 42px;
        box-shadow: none;
        border: 1px solid $blue-07;
      }

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator {
        color: #445470;
        font-size: 10px;

        &.react-select__loading-indicator {
          width: 46px;
          padding: 0;

          span {
            width: 5px;
            height: 5px;
            border-radius: 100%;
          }
        }
      }
    }

    .react-select__menu {
      z-index: 99;
    }

    &.react-select-container-multiple {
      .react-select__multi-value {
        color: rgba(0, 0, 0, 0.87);
        border: none;
        cursor: default;
        display: inline-flex;
        outline: 0;
        font-size: 0.8125rem;
        box-sizing: border-box;
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        align-items: center;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        white-space: nowrap;
        vertical-align: middle;
        justify-content: center;
        text-decoration: none;
        background-color: #e0e0e0;
        height: auto;
        padding: 4px 4px 4px 8px;
        border-radius: 4px;
        margin: 4px 8px 4px 0;

        .react-select__multi-value__label {
          padding: 0;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #0e1a2e;
        }

        .react-select__multi-value__remove {
          font-size: 8px;
          color: #5d697d;
          width: auto;
          height: auto;
          margin-right: 0;
          margin-left: 8px;
          font-family: 'icomoon' !important;
          speak: never;
          font-style: normal;
          font-weight: 400;
          font-feature-settings: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          &:hover {
            background: none;
            cursor: pointer;
          }

          &::before {
            content: '\e91d';
          }

          svg {
            display: none;
          }
        }
      }

      .react-select__menu {
        .react-select__option {
          position: relative;
          padding-left: 35px;

          &--is-selected {
            background: transparent;
            color: rgb(14, 26, 46);

            &::after {
              content: '\e91e';
              font-family: 'icomoon' !important;
              speak: never;
              font-style: normal;
              font-weight: 400;
              font-feature-settings: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              position: absolute;
              top: 12px;
              left: 10px;
              width: 10px;
              height: 10px;
              border-radius: 2px;
              color: rgb(0, 112, 216);
            }

            &::before {
              color: rgb(0, 112, 216);
            }
          }

          &::before {
            font-family: 'icomoon' !important;
            content: '\e91f';
            speak: never;
            font-style: normal;
            font-weight: 400;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            position: absolute;
            top: 12px;
            left: 10px;
            width: 10px;
            height: 10px;
            border-radius: 2px;
          }
        }
      }
    }
  }

  // end react select style

  .MuiSelect-select {
    border-radius: 4px;

    &:focus {
      border-radius: 4px;
    }

    &:focus {
      background-color: transparent;
    }
  }

  .MuiSelect-outlined {
    border: 1px solid $gray-05;
  }

  .MuiMenu-list {
    .MuiListItem-root {
      padding: 6px 12px;

      &.Mui-selected {
        background-color: transparent;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .MuiSelect-selectMenu {
    min-height: auto;
  }

  .MuiFormControl-root {
    &.multiple-select {
      width: 100%;

      .MuiInput-underline {
        &::before,
        &::after {
          display: none;
        }
      }

      .MuiInputBase-input {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 35px;
        min-height: 30px;
      }
    }
  }
}
