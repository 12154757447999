body {
  .splash-screen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    min-height: inherit;

    &.full-screen {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}
