body {
  .MuiTabs-root {
    min-height: auto;
    position: relative;
  }

  .MuiTabs-indicator {
    z-index: 5;
  }

  .MuiTab-root {
    padding: 15px 24px;
    min-width: auto;
    min-height: auto;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: $black-03;
    text-transform: none;
    font-weight: $font-medium;
  }

  .MuiTabs-indicator {
    background-color: $blue;
  }

  .MuiTab-textColorPrimary {
    &.Mui-selected {
      color: $blue;
    }
  }

  .MuiTabs-vertical {
    border-right: 1px solid $gray-03;
    flex-shrink: 0;

    .MuiTabs-indicator {
      right: auto;
      left: 0;
    }

    .MuiTab-wrapper {
      display: block;
    }

    .MuiTab-root {
      text-align: left;
      font-size: 14px;
      line-height: 18px;
      font-weight: $font-medium;
    }

    .MuiTab-textColorPrimary {
      &.Mui-selected {
        color: $blue-07;
        font-weight: $font-semi-bold;
      }
    }
  }
}

.vertical-tabs {
  display: flex;
  flex-grow: 1;
  background-color: $white;
  box-shadow: 0 1px 1px rgba(16, 30, 115, 0.16);
  border-radius: 4px;

  &__content {
    padding: 0 25px;
    width: 100%;
    min-height: 600px;
  }
}

.tab-content-wrapper,
.widget-content {
  padding: 12px 16px;
  min-height: 250px;

  .empty-data {
    min-height: 226px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: $d-blue-08;
    width: 100%;
  }

  &.padding-lg {
    padding: 40px;
  }

  &.padding-free {
    padding: 0;
  }

  .material-table-custom {
    .MuiTable-root {
      border-radius: 0 0 4px 4px;
    }
  }
}

.account-setting {
  &-content {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 320px);

    .tab-footer {
      margin: auto 0 0 0;
    }
  }

  &-section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  &-heading {
    letter-spacing: 0.2px;
    font-weight: $font-semi-bold;
    color: $black-03;
    margin-bottom: 24px;
  }
}

.setting-row {
  margin-bottom: 20px;

  h4 {
    margin-bottom: 0;
    color: $black-03;
  }

  .subtitle-light {
    color: $secondary-text-color;
    margin-bottom: 0;
  }

  .text-with-icon {
    margin-bottom: 12px;
  }
}

.tab-footer {
  @include media('<tablet') {
    .MuiButton-root {
      display: block;
      margin: 16px auto 0;
    }
  }

  .MuiButton-root {
    @include media('>=tablet') {
      margin-right: 16px;
    }
  }
}
