body {
  .MuiCheckbox-colorSecondary {
    &.Mui-checked {
      color: $blue-07;

      .MuiFormControlLabel-label {
        color: $blue-07;
      }
    }
  }

  .MuiCheckbox-root {
    color: $icons-color;
    padding: 0 14px 0 0;
    font-size: 12px;
    border-radius: 0;
  }
}

.checkbox-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 2px solid $d-blue-06;
  border-radius: 2px;

  &.checked {
    border-color: $blue-07;

    &::before {
      content: "\e923";
      font-family: 'icomoon' !important;
      color: $blue-07;
    }
  }
}