.modal-wrapper {
  &.content-scrolled {
    .modal-header {
      border-bottom: 1px solid $gray-04;
    }

    .modal-content {
      max-height: 370px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #bdbdbd;
      }
    }
  }
}

.modal-full-height {
  .modal-body {
    max-height: calc(100vh - 40px);
    overflow-y: auto;
  }
}

.modal-body {
  background-color: $white;
  border-radius: 4px;
  max-width: 500px;
  width: 100%;
  max-height: 70%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //padding: 24px 24px;
  color: $main-text-color;
  outline: none;

  .modal-header {
    padding-right: 60px;
  }

  .close-modal {
    display: inline-block;
    color: #323f4b;
    position: absolute;
    top: 25px;
    right: 20px;
    cursor: pointer;
  }

  .MuiButton-root {
    color: inherit;

    &.MuiButton-outlinedPrimary {
      color: #1976d2;
      border: 1px solid rgba(25, 118, 210, 0.5);

      &:hover {
        background: rgba(25, 118, 210, 0.1);
      }
    }

    &.MuiButton-containedPrimary {
      color: $white;
    }
  }

  @include media('<tablet') {
    width: calc(100% - 30px);
  }
}

.modal-header,
.modal-content,
.modal-footer {
  padding: 24px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    margin-bottom: 0;
    letter-spacing: 0.2px;
    font-weight: $font-semi-bold;
    display: flex;
    align-items: center;

    [class*='icon-'] {
      margin-right: 11px;
    }
  }

  & + form .modal-content,
  & + .modal-content {
    padding-top: 0;
  }
}

.modal-content {
}

.modal-footer {
  text-align: right;
  border-top: 1px solid $gray-04;
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;

  .MuiButton-root {
    margin-left: 16px;
  }
}

body {
  .MuiBackdrop-root {
    background-color: rgba(23, 23, 23, 0.8);
  }
}

.request-data {
  margin-top: 10px;
  margin-bottom: 24px;

  &__row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
  }

  &__type {
    width: 90px;
    flex-shrink: 0;
    margin-right: 8px;

    p {
      color: $d-blue-06;
    }
  }

  &__value {

    p {
      color: $black-03;
    }
  }
}