body {
  .MuiButtonBase-root {
    &.Mui-disabled {
      cursor: not-allowed;
    }
  }

  .MuiButton-root {
    min-width: auto;
    color: $white;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    font-weight: $font-semi-bold;
    font-family: $base-font-family;
    text-transform: none;
    padding: 12px 16px;
    border-radius: 4px;
    box-shadow: none;

    &.btn-low {
      padding: 8px 16px;
    }

    &.btn-square {
      padding: 13px 13px;
    }

    &.button-select {
      background-color: $white;
      padding: 8px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      font-weight: $font-regular;
      color: $black-03;
      border: 1px solid $gray-05;
      justify-content: flex-start;
      width: 260px;

      &.sm {
        width: 200px;
      }

      &.per-page-select {
        width: 60px;
      }

      &.MuiButton-fullWidth {
        width: 100%;
      }

      .MuiButton-label {

        .placeholder {
          color: $d-blue-05;
        }
      }

      .MuiButton-startIcon {
        [class*="icon-"] {
          color: $d-blue-06;
        }
      }

      .MuiButton-endIcon {
        margin-left: auto;
        margin-right: 0;
        color: $d-blue-06;

        &.MuiButton-iconSizeMedium > *:first-child {
          font-size: 10px;
        }

        [class*="icon-"] {
          margin-left: 15px;
          @include animate(transform);
        }
      }

      &:hover {
        border-color: $gray-06;
      }

      &[aria-controls] {
        border-color: $blue-07;

        .MuiButton-label {

          .placeholder {
            color: $black-03;
          }
        }

        .MuiButton-endIcon {

          [class*="icon-"] {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .MuiButton-sizeSmall {
    padding: 8px 10px;
  }

  .MuiButton-contained {
    background-color: $gray-02;
    color: $d-blue-06;
    box-shadow: none;

    &:hover,
    &:active {
      box-shadow: none;
    }

    &.button-success {
      background-color: $green-03;
      color: $white;

      &:hover {
        background-color: $green-02;
      }

      &:focus,
      &:active {
        background-color: $green-04;
      }
    }

    &.button-danger {
      background-color: $red-03;
      color: $white;

      &:hover {
        background-color: $red-02;
      }

      &:focus,
      &:active {
        background-color: $red-04;
      }
    }
  }

  .MuiButton-containedPrimary {
    background-color: $blue;
    color: $white;

    &:hover {
      background-color: $blue-08;
      box-shadow: none;
    }

    &.active,
    &:active {
      background-color: $blue-09;
      box-shadow: none;
    }

    &.Mui-disabled {
      background-color: $blue-07;
      opacity: .48;
      color: $white;
    }
  }

  .MuiButton-containedSecondary {
    background-color: $gray-02;
    color: $d-blue-06;

    &:hover {
      background-color: $gray-03;
      box-shadow: none;
    }

    &.active,
    &:active {
      background-color: $gray-04;
      box-shadow: none;
    }

    &.Mui-disabled {
      background-color: $gray-02;
      opacity: .48;
    }
  }

  .MuiButton-text {
    //padding: 0;

    .MuiButton-label {
      color: $d-blue-06;
    }
  }

  .MuiButton-textPrimary {
    color: $blue-07;
    font-weight: $font-regular;

    &:hover {
      color: $blue-09;
      background-color: transparent;
    }

    &.active,
    &:active {
      color: #036CB2;
    }
  }

  .MuiButton-outlined {
    border: 1px solid $gray-05;

    .MuiButton-label {
      color: #424B53;
    }
  }

  .MuiButton-outlinedPrimary {
    border: 1px solid $blue-07;

    .MuiButton-label {
      color: $blue-07;
    }

    &:hover {
      border: 1px solid $blue-08;

      .MuiButton-label {
        color: $blue-08;
      }
    }

    &:focus,
    &:active {
      border: 1px solid $blue-09;

      .MuiButton-label {
        color: $blue-09;
      }
    }
  }

  .MuiButton-startIcon {
    margin-left: 4px;
    margin-right: 13px;

    &.MuiButton-iconSizeMedium > *:first-child {
      font-size: 14px;
    }
  }

  // Icon button

  .MuiIconButton-root {
    font-size: 16px;
    padding: 12px;
    letter-spacing: 0;
    color: $d-blue-06;

    &[aria-controls] {
      color: $blue-07;
      background-color: #F2F5F8;
    }
  }
}

.btn-holder {
  padding: 0;

  &.btn-center {
    text-align: center;
  }
}