@import '../base/variables';
.password-toggler-wrap {
  position: relative;

  .MuiIconButton-root {
    padding: 6px;

    &.password-toggler-wrap__button {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);

      .MuiIconButton-label {
        color: $d-blue-06;
        font-size: 22px;
      }
    }
  }
}

body {
  .form-control {
    margin-bottom: 20px;
    position: static;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .MuiFormControl-root {
    &.input-crop {
      //width: 260px;
    }

    &.fullwidth {
      width: 100%;
    }

    &.number-input {
      width: 40px;
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .MuiInputBase-input {
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &.error {
        .MuiOutlinedInput-notchedOutline {
          border: 2px solid $danger;
        }
      }
    }

    &.input-success {
      .MuiOutlinedInput-notchedOutline {
        border-color: $green-02;
      }

      .MuiOutlinedInput-root {
        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: $green-02;
          }

          &:hover {
            .MuiOutlinedInput-notchedOutline {
              border-color: $green-02;
            }
          }
        }

        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: $green-02;
          }
        }
      }
    }
  }

  .MuiFormLabel-root {
    color: $placeholder-color;
    font-size: 16px;
    font-family: $base-font-family;

    &.Mui-focused {
      color: $blue;

      &.Mui-error {
        color: $red-02;
      }
    }
  }

  .MuiInputLabel-root {
    color: $d-blue-06;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    font-weight: $font-medium;
    margin-bottom: 4px;
  }

  .MuiOutlinedInput-root {
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $blue-07;
        border-width: 1px;
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: $blue-07;
        }
      }

      &.Mui-error {
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: $red-02;
          }
        }
      }
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: $gray-06;
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $red-02;
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $gray-05;
  }

  .MuiInputBase-root {
    font-size: 14px;
    line-height: 24px;
    font-family: $base-font-family;
    color: $black-03;
  }

  .MuiInputBase-input {
    height: auto;
    padding: 8px;
  }

  .MuiOutlinedInput-input {
    @include placeholder {
      color: $d-blue-05;
    }
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 13px) scale(1);
    color: #6A767E;
    font-size: 14px;
    font-weight: $font-regular;
  }

  // Helper text
  .MuiFormHelperText-root {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    font-weight: $font-regular;
    font-family: $base-font-family;
    margin-top: 4px;
    margin-left: 0;
    margin-right: 0;
    color: $d-blue-06;

    &.Mui-error {
      color: $red-02;
    }

    .helper-text-success {
      color: $green-02;
    }
  }

  // Filled Input
  .MuiFilledInput-root {
    background-color: #f2f5f8;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
    color: $secondary-text-color;

    @include placeholder {
      color: $secondary-text-color;
    }

    &:hover,
    &.Mui-focused {
      background-color: $white;
    }
  }

  .MuiFilledInput-adornedStart {
    padding-left: 18px;
  }

  .MuiFilledInput-underline {
    &::before,
    &::after {
      content: none;
    }
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 10px;
  }

  .MuiInputAdornment-root {
    height: 14px;
    max-height: none;

    .icon-search {
      color: #bdc2c7;
    }

    & > [class*='icon-'] {
      margin-right: 3px;
    }

    .MuiIconButton-root,
    [class*='icon-'] {
      font-size: 14px;
      color: $d-blue-06;

      &.icon-lg {
        font-size: 22px;
      }
    }

    &.MuiInputAdornment-positionEnd {
      margin-left: 0;

      .MuiIconButton-edgeEnd {
        margin: 0;
      }
    }
  }

  .MuiFilledInput-input {
    padding: 9px 18px 9px 0;

    &.MuiFilledInput-inputAdornedStart {
      padding-left: 10px;
    }
  }

  .MuiInputAdornment-filled {
    &.MuiInputAdornment-positionStart {
      margin-right: 10px;

      &:not(.MuiInputAdornment-hiddenLabel) {
        margin-top: 0;
      }
    }
  }

  .MuiOutlinedInput-multiline {
    padding: 8px;

    .MuiInputBase-input {
      padding: 0;
    }
  }
}

.search-box {
  margin-bottom: 11px;

  .MuiFormControl-root {
    max-width: 400px;
    width: 100%;
  }
}

.avatar-upload {
  input[type='file'] {
    display: none;
  }

  label {
    display: block;
    width: 104px;
  }

  .avatar-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    border-radius: 50%;
    background-color: #bdc2c7;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      .backdrop-layer {
        top: 0;
      }
    }

    .backdrop-layer {
      position: absolute;
      top: -100%;
      left: 0;
      width: 104px;
      height: 104px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: $black-03;
      opacity: 0.8;
      transition: all, 0.15s;

      span {
        color: $white;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        font-weight: $font-medium;
      }
    }

    [class*='icon-'] {
      color: $white;
      font-size: 90px;
    }
  }
}

.input-row {
  width: 100%;
  margin-bottom: 24px;

  &.sm {
    width: 350px;
  }
}
