#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $secondary-text-color;
  height: 80px;
  z-index: 999;
  display: flex;
  align-items: center;
}

.header-actions {
  display: flex;
  align-items: center;

  &__item {
    margin-left: 40px;
  }
}

.logo-link {
  display: block;
  width: 120px;

  img {
    width: 100%;
  }
}

.page-title {
  margin-bottom: 0;
  font-weight: $font-medium;
}

.menu-box {
  display: flex;
  align-items: center;

  .MuiIconButton-root {
    @include media('>=1280px') {
      display: none;
    }

    &.burger-button {
      color: $blue-04;
      font-size: 24px;
    }
  }
}
