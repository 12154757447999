@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?m4bt93');
  src: url('../../fonts/icomoon.eot?m4bt93#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?m4bt93') format('truetype'),
    url('../../fonts/icomoon.woff?m4bt93') format('woff'),
    url('../../fonts/icomoon.svg?m4bt93#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-day-off:before {
  content: "\e928";
}
.icon-skype:before {
  content: "\e927";
}
.icon-delete:before {
  content: "\e925";
}
.icon-edit:before {
  content: "\e926";
}
.icon-dots:before {
  content: "\e924";
}
.icon-check-mark:before {
  content: "\e923";
}
.icon-type-password:before {
  content: "\e922";
}
.icon-child:before {
  content: "\e920";
}
.icon-phone:before {
  content: "\e921";
}
.icon-checked:before {
  content: "\e91e";
}
.icon-not-checked:before {
  content: "\e91f";
}
.icon-close:before {
  content: "\e91d";
}
.icon-notification:before {
  content: "\e91c";
}
.icon-account:before {
  content: "\e91b";
}
.icon-arrow-drop-down:before {
  content: "\e91a";
}
.icon-menu:before {
  content: "\e917";
}
.icon-birthday-cake:before {
  content: "\e919";
}
.icon-search:before {
  content: "\e918";
}
.icon-HRMS-logo:before {
  content: "\e915";
}
.icon-DDI-logo:before {
  content: "\e916";
}
.icon-nav-item-5:before {
  content: "\e903";
}
.icon-done:before {
  content: "\e904";
}
.icon-travel:before {
  content: "\e905";
}
.icon-settings:before {
  content: "\e906";
}
.icon-nav-item-4:before {
  content: "\e907";
}
.icon-nav-item-3:before {
  content: "\e908";
}
.icon-nav-item-2:before {
  content: "\e909";
}
.icon-nav-item-1:before {
  content: "\e90a";
}
.icon-sick-leave:before {
  content: "\e90b";
}
.icon-day-off-v2:before {
  content: "\e90c";
}
.icon-vacation:before {
  content: "\e90d";
}
.icon-logout:before {
  content: "\e90e";
}
.icon-minus:before {
  content: "\e90f";
}
.icon-arrow-next:before {
  content: "\e910";
}
.icon-arrow-prev:before {
  content: "\e911";
}
.icon-plus:before {
  content: "\e912";
}
.icon-insert-invitation:before {
  content: "\e913";
}
.icon-mail:before {
  content: "\e914";
}
.icon-dot:before {
  content: "\e902";
}
.icon-eye:before {
  content: "\e900";
}
.icon-eye-outlined:before {
  content: "\e901";
}