body {
  .MuiAccordionSummary-expandIcon {
    transform: none;
    padding: 0;
    margin: 0;
    color: $icons-color;

    &.Mui-expanded {
      transform: none;

      .MuiIconButton-label {
        .icon-arrow-drop-down {
          transform: rotate(0deg);
        }
      }
    }

    .MuiIconButton-label {
      font-size: 10.5px;

      .icon-arrow-drop-down {
        transform: rotate(-90deg);
        @include animate(transform);
      }
    }

    &.MuiIconButton-root {
      &:hover {
        background-color: transparent;
        color: $icons-color;
      }
    }
  }

  .MuiAccordionSummary-content {
    margin: 0;

    &.Mui-expanded {
      margin: 0;
    }

    .employee-data-row {
      margin: 0;
      width: 100%;
    }

    .employee-data-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 5px;
    }
  }

  .MuiAccordionSummary-root {
    padding: 0 0 0 28px;
    min-height: auto;
    position: relative;

    .MuiAccordionSummary-expandIcon {
      position: absolute;
      left: 0;
    }

    &.Mui-expanded {
      min-height: auto;
    }
  }

  .MuiAccordion-root {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 8px;

    &::before {
      content: none;
    }

    &.Mui-expanded {
      margin: 0;

      &::before {
        opacity: 1;
      }
    }
  }

  .MuiAccordionDetails-root {
    padding: 8px 0;
  }
}

.approval-status {
  border: 1px solid $gray-05;
  border-radius: 2px;
  padding: 4px 8px;
  width: 100%;

  .MuiBadge-root {
    margin: 0 8px 0 0;
  }

  &__item {
    position: relative;
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-inline-end: 5px;

    .toggle-popper {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 100%;
      opacity: 0;
      z-index: 2;
    }
  }
}
