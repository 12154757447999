body {
  .MuiBreadcrumbs-ol {
    margin-bottom: 24px;

    .MuiBreadcrumbs-li {
      [class*='icon-'] {
        font-size: 18px;
        margin-right: 19px;
        color: $icons-color;
        transition: all, 0.2s;
      }

      .MuiLink-root,
      p {
        color: $main-text-color;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.1px;
        font-weight: $font-regular;
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }

      .MuiLink-root {
        transition: all, 0.2s;

        &:hover {
          color: $blue;

          [class*='icon-'] {
            color: inherit;
          }
        }
      }
    }

    .MuiBreadcrumbs-separator {
      font-size: 12px;
    }
  }
}
