body {
  .MuiPickersDay-daySelected {
    background-color: $blue;
    color: $white;
  }

  .MuiPickersDay-current {
    color: $blue;

    &.MuiPickersDay-daySelected {
      color: $white;
    }
  }

  .MuiIconButton-root {

    &.MuiPickersDay-day {
      padding: 4px;

      &:hover {
        background-color: #036cb2;
        color: $white;
      }
    }
  }
}
