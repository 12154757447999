body {
  .MuiFormControlLabel-root {
    margin: 0 0 18px 0;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    line-height: 20px;
    color: $black-03;
    font-family: $base-font-family;
  }

  .MuiRadio-root {
    padding: 0;
    margin-right: 10px;
    color: $d-blue-06;

    .MuiSvgIcon-root {
      width: 20px;
    }
  }

  .MuiRadio-colorPrimary {
    &.Mui-checked {
      color: $blue-07;
    }
  }
}

.input-row {
  .MuiFormControlLabel-label {
    font-size: 12px;
    line-height: 16px;
    color: $black;
    letter-spacing: 0.2px;
    font-weight: $font-medium;
  }

  .MuiFormControlLabel-root {
    margin-bottom: 16px;
  }
}