body {
  .MuiTypography-colorPrimary {
    color: $base-link-color;
  }

  .MuiLink-root {
    font-size: 14px;
    line-height: 22px;
    font-weight: $font-semi-bold;
    letter-spacing: 0.1px;
    color: $base-link-color;
    @include animate(color);

    &:hover {
      color: lighten($base-link-color, 20%);
    }
  }
}

.text-with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: $font-regular;
  letter-spacing: 0.1px;
  color: $main-text-color;

  [class*='icon-'] {
    font-size: 20px;
    color: $icons-color;
    margin-right: 12px;
  }

  &--sm {
    [class*='icon-'] {
      font-size: 14px;
      color: $icons-color;
      margin-right: 7px;
    }
  }
}

[class*='icon-'] {
  &.icon {
    &-green {
      color: $success;
    }

    &-yellow {
      color: $warning;
    }

    &-red {
      color: $danger;
    }

    &-blue {
      color: $blue;
    }
  }
}

.text-light {
  color: #25282b;
  opacity: 0.6;
}

.subtext {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.caption {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
