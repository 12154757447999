// Typography

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: $base-font-family;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 28px;
  color: $main-text-color;
}

h1,
.h1 {
  font-size: $h1-font-size;
  line-height: 40px;
  font-weight: $font-semi-bold;
  letter-spacing: 0.1px;
}

h2,
.h2 {
  font-size: $h2-font-size;
  line-height: 32px;
  font-weight: $font-medium;
  letter-spacing: 0.2px;
}

h3,
.h3 {
  font-size: $h3-font-size;
  line-height: 26px;
  font-weight: $font-semi-bold;
  letter-spacing: 0.2px;
}

h4,
.h4 {
  font-size: $h4-font-size;
  line-height: 24px;
  font-weight: $font-medium;
  letter-spacing: 0.2px;
}

h5,
.h5 {
  font-size: $h5-font-size;
  line-height: 1;
}

h6,
.h6 {
  font-size: $h6-font-size;
  line-height: 1;
}

.subtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: $font-medium;
  letter-spacing: 0.1px;
}

.subtitle-light {
  font-size: 14px;
  line-height: 18px;
  font-weight: $font-medium;
  letter-spacing: 0.1px;
}

.subtext {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  font-weight: $font-regular;
}

.text-sm {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: $font-medium;
}

p {
  margin: 0 0 1em;
}

a {
  color: $base-link-color;
  font-weight: $font-semi-bold;
  letter-spacing: 0.1px;
  font-size: 14px;
  line-height: 18px;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.text-center {
  text-align: center !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-semi-bold {
  font-weight: $font-semi-bold !important;
}

.text-medium {
  font-weight: $font-medium !important;
}