.login-form {
  background-color: $white;
  padding: 56px 129px;
  border-radius: 4px;
  max-width: 608px;
  width: 100%;
  min-height: 608px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;

  .form-label {
    color: $d-blue-06;
    font-size: 14px;
    margin-bottom: 23px;

    &.mb-14 {
      margin-bottom: 14px;
    }
  }

  @media (max-width: 608px) {
    padding: 30px 40px;
  }
  
  &.text-left {
    text-align: left;
  }

  &.start-content {
    justify-content: flex-start;
  }

  .form-row {
    .MuiButton-root {
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }

  .MuiFormLabel-root {
    text-align: left;
  }

  .logo {

    &:first-child {
      margin-top: 0;
      margin-bottom: 56px;
    }
  }

  &__content {

    &-title {
      margin-bottom: 24px;
      text-align: center;
    }

    .content-image {
      display: block;
      margin: 0 auto 40px;
    }

    &.letter-sent {

      p {
        margin-bottom: 40px;
        color: $d-blue-06;
        font-weight: $font-regular;

        .email {
          font-weight: $font-medium;
        }
      }
    }
  }

  .subtext {
    margin-top: 24px;
    display: inline-block;
  }

  .form-footer-container {
    max-width: 295px;
    width: 100%;
    margin: 0 auto;
  }
}

.form-row {
  margin: 0 0 24px;

  &.mb-40 {
    margin: 0 0 40px;
  }

  &.row-center {
    margin: 0 0 40px;
  }

  .MuiFormControl-root {
    width: 100%;

    label + .MuiInput-formControl {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .form-wrapper-control {
    display: table;
    max-width: 295px;
  }

  &.number-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .error-text {
    color: $red-02;
    display: block;
    font-size: 12px;
    font-weight: $font-regular;
    width: 100%;
    text-align: left;
  }

  &.error-row {
    background-color: $red-01;
    padding: 16px;
    border-radius: 2px;

    > span {
      color: $red-04;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
    }
  }

  &.row-center {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiFormControlLabel-root {
      margin: 0;
    }

    &+.form-footer {
      margin-top: 16px;
    }
  }

  .MuiButton-root {
    &.MuiButton-textPrimary {
      margin: 0;
    }
  }
}

.login-holder {
  width: 100%;

  &:not(.step) {

    .form-footer {
      width: 100%;
      margin: 0 auto;
    }

    .form-row {

      &.number-row {
        max-width: 302px;
        margin: 0 auto 24px;
      }
    }
  }

  .welcome-text {
    margin-bottom: 0;
    font-weight: $font-semi-bold;

    &+.logo {
      margin-top: 16px;
    }
  }

  p {
    margin-bottom: 24px;

    &.caption {
      text-align: center;
      font-size: 14px;
      margin-bottom: 40px;
    }
  }

  .logo {
    display: block;
    margin: 40px auto;
    max-width: 160px;
    width: 100%;
  }
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &+p.subtext {
    margin-top: 20px;
  }
}
