// Colors
$black: #000000;
$white: #fff;
$yellow: #ffcb11;
$bg-color: #f2f5f8;

// Black
$black-01: #e8e8e9;
$black-02: #d0d1d2;
$black-03: #0e1a2e;

// Light Gray
$gray-01: #fafbfc;
$gray-02: #f4f5f7;
$gray-03: #ebecf0;
$gray-04: #e3e5e9;
$gray-05: #d0d4db;
$gray-06: #c1c7d0;

// Blue
$blue-00: #e1f1fe;
$blue-01: #b7dcfd;
$blue-02: #87c7fc;
$blue-03: #52b1fb;
$blue-04: #18a0fb;
$blue-05: #0090fa;
$blue-06: #0082ec;
$blue-07: #0070d8;
$blue-08: #005fc7;
$blue-09: #0041a6;

// Dark Blue
$d-blue-00: #e8eaed;
$d-blue-01: #b9bfc9;
$d-blue-02: #a1a9b7;
$d-blue-03: #8a94a6;
$d-blue-04: #737f94;
$d-blue-05: #5b6982;
$d-blue-06: #445470;
$d-blue-07: #2c3e5e;
$d-blue-08: #15294c;
$d-blue-09: #0f1d35;

// Red
$red-01: #fbeaea;
$red-02: #d32f2f;
$red-03: #c62828;
$red-04: #b71c1c;

// Green
$green-01: #ebf4ec;
$green-02: #388e3c;
$green-03: #328036;
$green-04: #2D7230;

// Orange
$orange-01: #fef2e8;
$orange-02: #f57f17;

$blue: $blue-07;
$link-light-blue: $blue;

$danger: $red-02;
$warning: $orange-02;
$success: $green-02;

// Typography
$base-font-family: 'Montserrat', 'Helvetica', sans-serif;

// Headings FontSize
$h1-font-size: 32px !default;
$h2-font-size: 26px !default;
$h3-font-size: 20px !default;
$h4-font-size: 18px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;

// Text colors
$main-text-color: $black-03;
$secondary-text-color: #424b53;
$icons-color: #5d697d;
$placeholder-color: rgba($black, 0.6);

// Font Weight
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-black: 900;

// Box shadow
$blue-box-shadow: 0 8px 16px 0 rgba(0, 126, 255, 0.16);

// Body
$base-text-color: $main-text-color !default;
$base-background-color: $bg-color;
$font-size-base: 16px !default;
$line-height-base: 24px !default;
$base-font-family: $base-font-family !default;
$base-min-width: 375px;

// Links
$base-link-color: $link-light-blue;

// Animations
$animation-speed: 0.3s;
