.badge-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9px;
  height: 9px;
  border-radius: 50%;

  [class*='icon-'] {
    font-size: 5px;
    color: $white;
    line-height: 1;
  }

  &.success {
    background-color: $success;
  }

  &.error {
    background-color: $danger;
  }
}

body {
  .MuiBadge-root {
    &.notification-badge {
      cursor: pointer;

      .icon-notification {
        color: $white;
        font-size: 20px;
      }

      .MuiBadge-anchorOriginTopRightRectangle {
        top: -1px;
        left: 8px;
        transform: none;
      }
    }
  }

  .MuiBadge-badge {
    color: #f2f5f8;
    font-family: $base-font-family;
    font-weight: $font-semi-bold;

    &.MuiBadge-colorPrimary {
      background-color: $danger;
    }
  }
}
