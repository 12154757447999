body {
  .MuiChip-root {
    height: auto;
    padding: 4px 8px;
    border-radius: 4px;
  }

  .MuiChip-label {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $main-text-color;
    padding: 0;
  }

  .MuiChip-deleteIcon {
    font-size: 8px;
    color: $icons-color;
    width: auto;
    height: auto;
    margin-right: 0;
    margin-left: 8px;
  }
}

.selected-chip-items {
  width: 100%;
  display: flex;
  align-items: center;

  .all-chips {
    display: flex;
    flex-wrap: wrap;
  }

  .MuiChip-root {
    margin: 4px 8px 4px 0;
  }

  .close-all-chips {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f5f8;

    &:hover {
      [class*='icon-'] {
        color: lighten($icons-color, 20%);
      }
    }

    [class*='icon-'] {
      color: $icons-color;
      font-size: 12px;
    }
  }
}
