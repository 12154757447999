.employee-ticket {
  background-color: $white;
  max-width: 100%;
  height: 100%;
  padding: 10px 16px 0;
  border-radius: 4px;
  border: 1px solid $gray-03;
  display: flex;
  flex-direction: column;
  min-height: 282px;

  p:not(&__role, &__b-day) {
    margin-bottom: 0;
    word-break: break-word;
  }

  .text-with-icon {
    flex-wrap: wrap;
    font-size: 12px;
  }

  .MuiAvatar-root {
    margin-bottom: 8px;

    &.avatar-lg {
      width: 88px;
      height: 88px;
    }
  }

  .clickable-element {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__info-container {
    position: relative;
    padding-top: 13px;
    min-height: 62px;

    &::before {
      content: '';
      width: calc(100% + 32px);
      height: 1px;
      position: absolute;
      top: 0;
      left: -16px;
      background: $gray-03;
    }
  }

  &__no-data {
    font-size: 12px;
    line-height: 16px;
    color: $d-blue-06;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    font-weight: $font-medium;
    color: $main-text-color;
  }

  &__role {
    color: $secondary-text-color;
    margin-bottom: 14px;
    display: flex;
  }

  &__b-day {
    font-size: 14px;
    color: $main-text-color;
    margin-bottom: 10px;
    position: relative;
    padding-left: 28px;

    [class*='icon-'] {
      font-size: 18px;
      color: $icons-color;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }

  &__contacts {
    margin-bottom: 10px;
    position: relative;
    padding-left: 28px;

    [class*='icon-'] {
      font-size: 16px;
      color: $icons-color;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }


    [class*='icon-skype'] {
      font-size: 20px;
    }
  }
}

.employee-container {
  background-color: $white;
  padding: 16px 8px;
  box-shadow: 0 1px 1px rgba(16, 30, 115, 0.16);
  border-radius: 4px;
}

.pagination-holder {
  width: 100%;
  padding: 0 8px;

  .pagination-left {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #25282b;
      margin-left: 40px;
    }
  }
}

.employee-dash-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
  min-height: calc(100vh - 340px);
  position: relative;
  align-items: flex-start;

  .page-loader {
    box-shadow: none;
    min-height: calc(100vh - 336px);
  }

  .empty-data {
    width: 100%;
    min-height: calc(100vh - 336px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .employee-dash-col {
    width: 100%;
    padding: 0 8px;
    margin-bottom: 16px;

    @include media('>=tablet') {
      width: 50%;
    }

    @include media('>=desktop') {
      width: 33.33%;
    }

    @include media('>=largescreen') {
      width: 20%;
    }
  }
}

.page-wrap {

  &.sidebar-open {

    .employees-grid {

      .employee-info-card {

        @include media('>=tablet') {
          width: 50%;
        }

        @include media('>=largescreen') {
          width: 33.33%;
        }
      }
    }
  }
}

.employees-grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .employee-info-card {
    width: 100%;
    padding-right: 16px;

    @include media('>=tablet') {
      width: 50%;
    }

    @include media('>=1400px') {
      width: 33.33%;
    }
  }
}

.employee-info-card {
  display: flex;
  align-items: center;
  margin: 12px 0;

  &__data {
    margin-left: 8px;
    padding-right: 10px;
    display: block;
    flex: 0 0 100%;
    max-width: calc(100% - 48px);
    width: calc(100% - 48px);
    font-size: 12px;

    &--name {
      margin-bottom: 0;
      font-weight: $font-medium;
    }

    &--role {
      margin-bottom: 4px;
      color: $secondary-text-color;
    }

    &--info {
      margin-bottom: 0;
      color: $main-text-color;
      font-size: 12px;

      [class*='icon-'] {
        margin-right: 4px;
        font-size: 14px;
      }
    }
  }
}

.employee-header {

  .custom-toolbar {
    background-color: $white;
    padding: 16px 18px;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(16, 30, 115, 0.16);
    border-radius: 4px;
  }
}
