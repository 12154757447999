.pagination-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body {
  .MuiPaginationItem-root {
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    min-width: 30px;
    font-family: $base-font-family;
    font-size: 14px;
    line-height: 18px;
    color: #25282b;
    letter-spacing: 0;
    border-radius: 3px;

    .MuiPaginationItem-icon {
      font-size: 2rem;
    }
  }

  .MuiPaginationItem-textPrimary {
    &.Mui-selected {
      border: 1px solid $blue-04;
      background: transparent;
      color: #25282b;

      &:hover {
        border: 1px solid darken($blue-04, 10%);
        background: transparent;
      }
    }
  }

  .per-page {
    color: #25282b;
    letter-spacing: 0.1px;
    font-family: $base-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;

    span {
      color: #25282b;
      opacity: 0.6;
      font-family: $base-font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
    }

    .MuiInput-underline {
      &:before {
        display: none;
      }
    }

    .MuiFormControl-root {
      margin: 0 10px 0 8px;

      .MuiSelect-icon.MuiSvgIcon-root {
        width: 12px;
        height: 7px;
        top: calc(50% - 4px);
      }
    }

    //.MuiSelect-select.MuiSelect-select {
    //  font-family: $base-font-family;
    //  color: #25282b;
    //  letter-spacing: 0.2px;
    //  font-style: normal;
    //  font-weight: 600;
    //  font-size: 14px;
    //  line-height: 18px;
    //  padding-top: 0;
    //  padding-bottom: 0;
    //  padding-right: 18px;
    //}
    .MuiOutlinedInput-root {
      .MuiSelect-select.MuiSelect-select {
        border: none;
        position: relative;
      }

      .MuiSelect-outlined.MuiSelect-outlined {
        padding-right: 36px;
        padding-left: 10px;
      }

      .PrivateNotchedOutline-root-16 {
        top: -8px;
      }

      .icon-arrow-drop-down {
        font-size: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;
      }
    }
  }
}
