$breakpoints: (
  'xs-phone': 400px,
  'phone': 520px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px,
  'largescreen': 1600px,
);

@import 'vendors/include-media';
@import 'vendors/normalize';
@import 'vendors/icomoon';

@import 'base/variables';
@import 'base/helpers';
@import 'base/mixins';
@import 'base/reset';
@import 'base/typography';

@import 'general/flex';

@import 'layout/header';
@import 'layout/common';
@import 'layout/sidebar';
@import 'general/spacing';

// Accordion
@import 'components/accordion';

// Avatar
@import 'components/avatar';

// Badge
@import 'components/badge';

// Buttons
@import 'components/buttons';

// Breadcrumbs
@import 'components/breadcrumbs';

// Dropdowns
@import 'components/dropdown';

// Inputs
@import 'components/input';

// Select
@import 'components/select';

// DatePicker
@import 'components/datepicker';

// Modals
@import 'components/modals';

// Material table
@import 'components/material-table';

// Tabs
@import 'components/tabs';

// Chip
@import 'components/chip';

// Checkbox
@import 'components/checkbox';

// Radio
@import 'components/radio';

// Pagination
@import 'components/pagination';

// Custom Elements
@import 'components/approve-status';
@import 'components/login';
@import 'components/text';
@import 'components/widget';
@import 'components/employee-card';
@import 'components/splash-screen';

* {
  font-family: $base-font-family;
}

body {
  margin: 0;
  width: 100%;
  position: relative;
  min-width: $base-min-width;
  background-color: $base-background-color;
  font-family: $base-font-family;
  letter-spacing: 0.1px;
}

#wrapper {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

.container {
  max-width: 1522px;
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;

  &.fullwidth {
    max-width: 100%;
  }
}

::selection {
  background-color: $blue;
  color: $white;
}
