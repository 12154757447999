ul,
ol {
  @extend %listreset;
}

.link {
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  font-weight: $font-semi-bold;
  letter-spacing: 0.1px;
  color: $base-link-color;
  @include animate(color);

  &:hover {
    color: lighten($base-link-color, 20%);
  }
}

sup {
  font-size: 50%;
}

h2 {
  sup {
    top: -14px;
    font-size: 35%;
  }
}

.full-height-holder {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  .full-height-inner {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
  }
}

.encoded-phone-number {
  font-weight: $font-medium;

  .dot-group {
    margin-left: 7px;
    display: inline-flex;
    align-items: center;

    .icon-dot {
      font-size: 9px;
      line-height: 1;
      margin: 0 1px;
    }
  }
}

#header {
  & + .page-wrap {
    margin-top: 80px;
    min-height: calc(100vh - 80px);

    .sidebar {
      &.sidebar-open {
        width: 316px;
      }

      @include media('<=1280px') {
        width: 0;
      }


      .MuiDrawer-paper {
        top: 80px;
        height: calc(100% - 80px);
      }
    }
  }
}

.relative {
  position: relative;
}

.z-1 {
  z-index: 1;
}

.page-wrap {
  transition: all, 0.2s;
  overflow: hidden;
  min-height: 100vh;
  display: flex;

  &.sidebar-open {
    .content {
      @include media('>=1280px') {
        //margin-left: 316px;
      }
    }
  }

  .content {
    //margin-left: 104px;
    padding: 28px 0;
    transition: all, .2s;
    width: 100%;

    @include media('<1280px') {
      margin-left: 0;
    }
  }

  .widget {
    .loading-plug {
      min-height: 300px;
      position: relative;
    }
  }

  .page-loader {
    text-align: center;
    display: flex;
    width: calc(100% - 24px);
    margin: 0 12px 15px;
    min-height: calc(100vh - 230px);
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(16, 30, 115, 0.16);
    border-radius: 8px;

    &.page-loader-opacity {
      background-color: rgba(255, 255, 255, 0.5);
      box-shadow: none;
    }

    &.page-loader-absolute {
      position: absolute;
      top: 11px;
      left: 24px;
      width: calc(100% - 48px);
      height: calc(100% - 24px);
      min-height: auto;
      z-index: 6;
      margin: 0;
    }

    &-container {
      margin: auto;
    }

    &-bar {
      width: 85px;
      height: 8px;
      background: #f2f4f6;
      border-radius: 2px;
      display: block;
      margin: 14px auto;
      overflow: hidden;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 8px;
        border-radius: 2px;
        background: #2ac769;
        animation: 2s linear 0s infinite alternate loader-animation;
      }
    }
  }
}

.loader-gif {
  img {
    margin: auto;
    display: block;
  }
}

@keyframes loader-animation {
  from {
    transform: translateX(-120%);
  }
  to {
    transform: translateX(120%);
  }
}

.clickable-element {
  cursor: pointer;
}

hr {
  &.vertical-text-divider {
    margin: 0 8px;
    height: 22px;
    width: 1px;
    background-color: #e8e8e8;
    border: none;
    display: inline-block;
  }
}

.no-data {
  padding: 50px 20px;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

.component-box {
  padding: 20px;
}

.text-overflow {
  display: block;
  white-space: nowrap;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-sick-leave-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  background-color: $danger;
}

.icon-vacation-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  background-color: $success;
}

.icon-day-off-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  background-color: $warning;
}
