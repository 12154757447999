.widget {
  background: $white;
  box-shadow: 0 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 24px;

    @include media('>=desktop') {
      margin-bottom: 48px;
    }
  }
}

.employee-card {
  height: 100%;

  .text-medium {
    font-weight: $font-medium;
  }

  .icon-dots {
    color: $d-blue-06;
    cursor: pointer;
  }

  &__info {
    &--in-row {
      display: flex;
      align-items: center;

      .MuiAvatar-root {
        margin-right: 16px;
      }
    }
  }

  .employee-data-row {
    .text-with-icon.text-with-little-icon {
      [class*='icon-'] {
        font-size: 13px;
        color: #78838d;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
    letter-spacing: 0.1px;
  }

  .employee-data-row {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  .employee-info {
    .text-with-icon {
      margin: 0;
    }

    .employee-name {
      color: $main-text-color;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 2px;
      letter-spacing: 0.2px;
      font-weight: $font-semi-bold;
    }

    .employee-role {
      color: $secondary-text-color;
    }
  }

  &__section {
    padding: 24px;

    &.border-bottom {
      position: relative;

      &::after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: $gray-04;
        position: absolute;
        left: 0;
        top: 100%;
      }
    }

    .btn-holder {
      &:last-child {
        padding-top: 10px;
      }
    }
  }

  &__section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__section-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: $font-semi-bold;
    margin-bottom: 0;
    letter-spacing: 0.2px;
  }

  .MuiButton-root {
    & + .employee-card__section {
      margin-top: 25px;
    }
  }
}

.widget-header {
  border-bottom: 1px solid $gray-04;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .link {
    margin: 0 16px;
    flex-shrink: 0;
  }

  &__title {
    font-weight: $font-medium;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: $main-text-color;
    margin-bottom: 0;
    padding: 15px 24px;
  }
}
