body {
  .MuiDrawer-paper {
    border: none;
    box-shadow: 4px 0 16px rgba(16, 30, 115, 0.08);
    transition: all, 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
  }

  .drawer {
    &-open {
      width: 316px;

      .MuiDrawer-paper {
        width: 316px;
      }

      .brand-link {
        display: block;
      }
    }

    &-close {
      width: 104px;

      .MuiDrawer-paper {
        width: 104px;
      }

      .sidebar-toggle {
        &.MuiButtonBase-root {
          .icon-arrow-prev {
            transform: rotate(180deg);
          }
        }
      }

      .sidebar-menu {
        padding-left: 0;
        padding-right: 0;

        &__item {
          display: flex;
          justify-content: center;
        }

        &__link {
          [class*='icon-'] {
            margin: 0;
          }
        }
      }

      .site-logo {
        padding: 32px 0;
        justify-content: center;
      }

      .copyright-list {
        padding: 0 10px 24px;

        li {
          padding: 8px 0 0;
        }
      }

      .MuiIconButton-root {
        margin: 0;
      }

      .brand-link {
        display: none;
      }
    }
  }
}

.sidebar-toggle {
  &.MuiButtonBase-root {
    width: 32px;
    height: 32px;
    background-color: #f2f5f8;
    border-radius: 10px 0 0 10px;
    position: absolute;
    top: 28px;
    right: 0;
    border: 0;
    z-index: 999;
    transition: all, 0.2s;

    .icon-arrow-prev {
      color: #a9afba;
      font-size: 12px;
      line-height: 1;
      transition: all, 0.2s;
    }

    &:hover {
      background-color: darken(#f2f5f8, 10%);

      .icon-arrow-prev {
        color: $secondary-text-color;
      }
    }
  }
}

.site-logo {
  display: flex;
  align-items: center;
  padding: 32px 51px;

  .brand-link {
    transition: all, 0.2s;
  }

  .MuiIconButton-root {
    padding: 0;
    margin: 0 10px 0 0;
  }

  .only-logo {
    margin: 0 10px 0 0;
  }
}

.sidebar-menu {
  padding: 24px 40px;

  &__item {
    margin-bottom: 9px;
    position: relative;

    &:last-child {
      &::before {
        content: '';
        display: block;
        width: calc(100% + 102px);
        height: 1px;
        background-color: #e8e8e8;
        position: absolute;
        top: -2px;
        left: -51px;
      }
    }

    &.active {
      .sidebar-menu__link {
        [class*='icon-'] {
          color: $blue;
        }

        .menu-item-text {
          color: $blue;
        }
      }
    }
  }

  &__link {
    padding: 11px 10px;
    display: flex;
    align-items: center;

    [class*='icon-'] {
      color: $icons-color;
      margin-right: 10px;
      font-size: 18px;
    }

    .menu-item-text {
      color: $main-text-color;
      font-size: 14px;
      line-height: 18px;
      font-weight: $font-semi-bold;
      letter-spacing: 0.2px;
    }

    &:hover {
      background-color: #f2f5f8;
      border-radius: 3px;
    }
  }
}

.copyright-list {
  padding: 18px 34px 24px;
  text-align: center;

  li {
    display: inline-block;
    position: relative;
    padding: 8px 8px 0;

    .MuiLink-root,
    span,
    a {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.1px;
      color: #25282b;
      font-weight: $font-regular;
    }

    a {
      color: $blue;
    }

    .copyright {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      margin-top: 8px;
      display: block;
    }

    &:last-child {
      width: 100%;
    }
  }
}
